import React from 'react';

import ButtonsUpDown from "../tools/btns-updn";
import ButtonsLfRt from "../tools/btns-lfrt";
import { detectHRV, detectBeatInfo, detectRhythmStat, drawBarDiagram } from "../tools/beat-funcs";

import { detectRecordDate } from '../tools/date-funcs';
import {
    ECG_AMP_LIST, MAX_ECG_AMP_INDEX,
    PAPER_SPEED_LIST, MAX_PAPER_SPEED_INDEX
} from '../../const/signal_consts';
import { RSI_AFIB, RSI_ARRHYTHMIA_SINUS, RSI_EXTRASYSTOLE, RSI_REGULAR_SINUS } from '../tools/calc-funcs';
import { getRhythmItem, getLinearData, getArray, drawPlot } from '../tools/signal-funcs';


const css = `
.ecg-tool-container {
    height: 160px;
}

.ecg-tool-panel {
    margin: auto;
    display: flex;
}

#ecg-gain {
  display: inline-block;
  left: 3px;
  top: 1px;
  width: 26px;
}

#ecg-gain i {
  font-size: 1.7em;
}

#ecg-speed {
  display: inline-block;
  left: 1px;
  width: 26px;
}

#ecg-speed i {
  font-size: 1.3em;
}

.hr-span {
  padding-top: 8px;
  padding-bottom: 18px;
}

.hr-section {
   padding-left: 13pt;
   min-width: 160pt;  
}


#rhythm-section {
  min-width: 110pt;
}

.ecg-param-title-b {
  margin: 8px 4px 0px 6px;
  font-size: 16pt;
}

#ecg-rec-date {
  font-size: 13pt;
  padding: 0px 0 0 6px;
  margin-top: 4px;
}

#btn-process {
  position: relative;
  left: 57px;
  top: -2px;
}

#btn-process button {
  padding: 2px 12px 0 12px;
}

.ecg-param-value-b {
  margin: 8px 4px 0px 6px;
  font-size: 16pt;
  font-weight: bold;
  color: blue;
}

.ecg-param-unit-b {
  font-size: 10pt;
}


.ecg-param-title-s {
  margin: 0px 4px;
  font-size: 10pt;
  min-width: 50px;
}

.ecg-param-value-s {
  margin: 0px 4px;
  min-width: 35px;
  font-size: 10pt;
  font-weight: bold;
  text-align: right;
  color: blue;
}

.ecg-param-unit-s {
  font-size: 9pt;
}

.ecg-control-param .x-btn-info {
    white-space: nowrap;
    min-width: 88px;
}

.ecg-control-param .x-btn-info:hover {
    background-color: #808080;
}


.ecg-param-label {
   display: block;
   margin: 8px 0px 0px 6px;
}

.ecg-group-label {
  font-size: 13pt;
  margin: 0px 0px 0px 0px;

}

.ecg-control-group {
  margin: 6px 0px 4px 6px;
}

.group-divisor {
  margin-left: 12px;
  padding-left: 8px;
  border-left: 1px dotted gray;
}

.ecg-control-group .form-check {
  margin-left: 4px;
}

.ecg-control-group .form-check label {
  font-size: 9pt;
}

.bold {
   font-weight: bold; 
}

`;


const ToolPanel = (props) => {

    const { w, nUnit, totalLength, rec_date, proc_ver, proc_date, frequency, beats, pw, rhythm, controls } = props;
    // console.log(controls);

    const signalGain = ECG_AMP_LIST[controls.signalGainIndex];
    const paperSpeed = PAPER_SPEED_LIST[controls.paperSpeedIndex];

    const onIncEcgGainIndex = () => {
        if (controls.signalGainIndex < MAX_ECG_AMP_INDEX) {
            controls.setSignalGainIndex(controls.signalGainIndex + 1);
        }
    }

    const onDecEcgGainIndex = () => {
        if (controls.signalGainIndex > 0) {
            controls.setSignalGainIndex(controls.signalGainIndex - 1);
        }
    }

    const onIncPaperSpeedIndex = () => {
        if (controls.paperSpeedIndex < MAX_PAPER_SPEED_INDEX) {
            controls.setPaperSpeedIndex(controls.paperSpeedIndex + 1);
        }
    }

    const onDecPaperSpeedIndex = () => {
        if (controls.paperSpeedIndex > 0) {
            controls.setPaperSpeedIndex(controls.paperSpeedIndex - 1);
        }
    }

    const ecgGainStr = `${signalGain} mm/mkV`;
    const paperSpeedStr = `${paperSpeed} mm/s`;

    const recDate = detectRecordDate(rec_date);

    const hrv = detectHRV(beats, frequency);
    const beatInfo = detectBeatInfo(beats, frequency, controls.beatMode? controls.selectedBeat : -1);
    const rh = detectRhythmStat(rhythm, frequency, totalLength);
    const rr_hr_diag = hrv.n > 1 ? controls.rr_hr_mode ? drawBarDiagram(hrv.hr_hist, 200, 20, 180, 110, "bps") : drawBarDiagram(hrv.rr_hist, 2000, 200, 180, 110, "ms") : null;
    const pwPlot = pw ? drawPlot(getLinearData(0, 75, 0.01), getArray(pw), 180, 110) : null;



    const dateSpan = (
        <div className='ecg-control-param'>
            <span className="bullet-icon" title="Record start date and time"><i className="fa fa-calendar fa-lg"></i></span>
            <span id="ecg-rec-date" title={proc_date ? `Processed: ${proc_ver} timestamp: ${proc_date}` : "Unprocessed"}>{recDate ? recDate : "N/A"}</span>
        </div>
    );

    const hrSpan = beats ? (
        <div className='ecg-control-param hr-span'>
            <span className="bullet-icon" title="Heart Rate"><i className="fa fa-heart cl-red fa-lg"></i></span>
            <span className="ecg-param-value-b" title="Heart Rate">{hrv.n > 1 ? hrv.hr : "N/A"}</span>
            <span className="ecg-param-unit-b">{hrv.n > 1 ? "bpm" : ""}</span>
            <span id="btn-process">
                <button className="btn btn-outline-secondary btn-sm" title="Re-process record"><i className="fa fa-recycle" onClick={controls.onRecalcRecord}></i></button>
            </span>
        </div>
    ) : (
        <div className='ecg-control-param'>
            <label className="ecg-param-title-b">Unprocessed</label>
            <span id="btn-process">
                    <button className="btn btn-outline-secondary btn-sm" title="Process record"><i className="fa fa-recycle" onClick={controls.onRecalcRecord}></i></button>
            </span>
        </div>

    );

    const gainSpan = (
        <div className='ecg-control-param'>
            <span id="ecg-gain" className="bullet-icon" title="Signal Gain"><i className="fa fa-sort"></i></span>
            <ButtonsUpDown value={ecgGainStr} onIncIndex={onIncEcgGainIndex} onDecIndex={onDecEcgGainIndex} />
        </div>
    );

    const speedSpan = (
        <div className='ecg-control-param icon-param'>
            <span id="ecg-speed" className="bullet-icon" title="Paper Speed"><i className="fa fa-tachometer"></i></span>
            <ButtonsLfRt value={paperSpeedStr} onIncIndex={onIncPaperSpeedIndex} onDecIndex={onDecPaperSpeedIndex} />
        </div>
    );

    const hrSection = (
        <div className="ecg-control-group hr-section">
            {dateSpan}
            {hrSpan}
            {gainSpan}
            {speedSpan}
        </div>

    );

    const intervalLabel = controls.rr_hr_mode ? "Instant HRs" : "R-R intervals";

    const controlOptions = beats ? (
        <>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="inputRawData" defaultChecked={controls.showRawData} onClick={controls.onToggleShowRawData} />
                <label className="form-check-label" htmlFor="inputRawData">Raw data</label>
            </div>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="inputSmoothedData" defaultChecked={controls.showSmoothedData} onClick={controls.onToggleShowSmoothedData} />
                <label className="form-check-label" htmlFor="inputSmoothedData">Smoothed data</label>
            </div>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="inputRevPolarity" defaultChecked={controls.showRevPolarity} onClick={controls.onToggleShowRevPolarity} />
                <label className="form-check-label" htmlFor="inputRevPolarity">Reversed polarity</label>
            </div>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="inputShowRRs" defaultChecked={controls.showRRs} onClick={controls.onToggleShowRRs} />
                <label className="form-check-label" htmlFor="inputShowRRs">{intervalLabel}</label>
            </div>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="inputShowBeatMarkers" defaultChecked={controls.showBeatMarkers} onClick={controls.onToggleShowBeatMarkers} />
                <label className="form-check-label" htmlFor="inputShowBeatMarkers">Beat markers</label>
            </div>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="inputShowRhythm" defaultChecked={controls.showRhythm} onClick={controls.onToggleShowRhythm} />
                <label className="form-check-label" htmlFor="inputShowBeatMarkers">Rhythm marking</label>
            </div>

        </>
    ) : (
        <>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="inputRawData" defaultChecked="1" onClick={controls.onToggleShowRawData} />
                <label className="form-check-label" htmlFor="inputRawData">Raw data</label>
            </div>
            <div className="form-check disabled">
                <input type="checkbox" className="form-check-input" disabled id="inputSmoothedData" />
                <label className="form-check-label " htmlFor="inputSmoothedData">Smoothed data</label>
            </div>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" id="inputRevPolarity" defaultChecked={controls.showRevPolarity} onClick={controls.onToggleShowRevPolarity} />
                <label className="form-check-label" htmlFor="inputRevPolarity">Reversed polarity</label>
            </div>
        </>
    );

    const viewSection = (
        <div className='ecg-control-group group-divisor'>
            <label className={controls.noCutSignal ? "ecg-group-label bold" : "ecg-group-label"} 
                   onClick={controls.onToggleNoCutSignal}>View</label>
            {controlOptions}
        </div>
    );

    const hrvSection = hrv && (hrv.nn > 5) && (nUnit > 1) ? (
        <div className='ecg-control-group group-divisor'>
            <label className="ecg-group-label">HRV</label>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">NN</label>
                <label className="ecg-param-value-s">{hrv.nn}</label>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">Avg RR</label>
                <label className="ecg-param-value-s">{hrv.rrMean}</label>
                <span className="ecg-param-unit-s">ms</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">SDNN</label>
                <label className="ecg-param-value-s">{hrv.sdnn}</label>
                <span className="ecg-param-unit-s">ms</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">RMSSD</label>
                <label className="ecg-param-value-s">{hrv.rmssd}</label>
                <span className="ecg-param-unit-s">ms</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">pNN-20</label>
                <label className="ecg-param-value-s">{hrv.pnn20}</label>
                <span className="ecg-param-unit-s">%</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">pNN-50</label>
                <label className="ecg-param-value-s">{hrv.pnn50}</label>
                <span className="ecg-param-unit-s">%</span>
            </div>
        </div>

    ) : null;

    const annotationItem = controls.hasAnnotation ? (
        <input type="checkbox" className="form-check-input" id="inputAnnotation"
            checked={controls.showAnnotation} onChange={controls.onToggleShowAnnotation} />
    ) : (
            <input type="checkbox" className="form-check-input" id="inputAnnotation"
                disabled="disabled"
                checked={false} onChange={controls.onToggleShowAnnotation} />
    ) ;


    const rhythmSection = rhythm && (nUnit > 1) ? (
        <div id="rhythm-section" className='ecg-control-group group-divisor'>
            <label className="ecg-group-label">Rhythm</label>
            {getRhythmItem("NSR", "Normal sinus", rh.tRS, rh.pRS, rh.mRS, RSI_REGULAR_SINUS)}
            {getRhythmItem("SA", "Sinus arrythmia", rh.tSA, rh.pSA, rh.mSA, RSI_ARRHYTHMIA_SINUS)}
            {getRhythmItem("E", "Extrasystole", rh.tEx, rh.pEx, rh.mEx, RSI_EXTRASYSTOLE)}
            {getRhythmItem("AFib", "Atrial fibrillation", rh.tAF, rh.pAF, rh.mAF, RSI_AFIB)}
            {getRhythmItem("NoS", "Noisy or no signal", rh.tUndef, rh.pUndef, rh.mUndef, 0)}
            <div className="form-check">
                {annotationItem}
                <label className="form-check-label" htmlFor="inputAnnotation">Show annotation</label>
            </div>

        </div>
    ) : null;

    const diagramLabel = controls.rr_hr_mode ? "HR diagram" : "RR diagram"

    const rrDiagramSection = rr_hr_diag && (nUnit > 1) ? (
        <div className='ecg-control-group group-divisor prevent-select' onClick={controls.onToggleRR_HR_Mode}>
            <label className="ecg-group-label" >{diagramLabel}</label>
            <div style={{ marginTop: "8px" }}>
                {rr_hr_diag}
            </div>
        </div>
    ) : null;

    const pwPlotSection = pwPlot && (nUnit > 2) ? (
        <div className='ecg-control-group group-divisor'>
            <label className="ecg-group-label">Signal spectrum</label>
            <div style={{ marginTop: "8px" }}>
                {pwPlot}
            </div>
        </div>
    ) : null;

    const beatInfoSection = beatInfo && (nUnit > 2) ? controls.beatInfoPage === 0 ? (
        <div className='ecg-control-group group-divisor'>
            <label className="ecg-group-label" onClick={controls.onToggleBeatInfoPage}>Beat info {beatInfo.n ? `#${beatInfo.n}`: ``}</label>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">RR</label>
                <label className="ecg-param-value-s">{beatInfo.rr}</label>
                <span className="ecg-param-unit-s">ms</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">HR</label>
                <label className="ecg-param-value-s">{beatInfo.hr}</label>
                <span className="ecg-param-unit-s">bpm</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s" title="Beat width (QRS)">Wqrs</label>
                <label className="ecg-param-value-s">{beatInfo.w}</label>
                <span className="ecg-param-unit-s">ms</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">ST</label>
                <label className="ecg-param-value-s">{beatInfo.st}</label>
                <span className="ecg-param-unit-s">mkV</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">PQ</label>
                <label className="ecg-param-value-s">{beatInfo.pq}</label>
                <span className="ecg-param-unit-s">ms</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s">QT</label>
                <label className="ecg-param-value-s">{beatInfo.qt}</label>
                <span className="ecg-param-unit-s">ms</span>
            </div>
        </div>
    ) : (
        <div className='ecg-control-group group-divisor'>
            <label className="ecg-group-label bold" onClick={controls.onToggleBeatInfoPage}>Beat det-info {beatInfo.n ? `#${beatInfo.n}`: ``}</label>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s" title="Relation to the previous beat RR">NN</label>
                <label className="ecg-param-value-s">{beatInfo.nn}</label>
                <span className="ecg-param-unit-s">{beatInfo.nn1} / {beatInfo.nn2}</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s" title="Signal-to-noise ratio">SNR</label>
                <label className="ecg-param-value-s">{beatInfo.snr}</label>
                <span className="ecg-param-unit-s">{beatInfo.f_snr}</span>
                <span className="ecg-param-unit-s"> dB</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s" title="Beat detection quality index (QI)">QI</label>
                <label className="ecg-param-value-s">{beatInfo.qi}</label>
                <span className="ecg-param-unit-s">%</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s" title="Beat deformation index (DI)">DI</label>
                <label className="ecg-param-value-s">{beatInfo.di}</label>
                <span className="ecg-param-unit-s">%</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s" title="Beat grouping (group number / capacity)">Grouping</label>
                <label className="ecg-param-value-s">{beatInfo.g !== undefined ? beatInfo.g+1 : "-"}</label>
                <span className="ecg-param-unit-s">{beatInfo.gc}</span>
            </div>
            <div className='ecg-param-group'>
                <label className="ecg-param-title-s" title="Beat type code detected by NN">ML code</label>
                <label className="ecg-param-value-s">{beatInfo.z !== undefined ? beatInfo.z : "-"}</label>
                <span className="ecg-param-unit-s">{beatInfo.cd ? beatInfo.cd : ""}</span>
            </div>
        </div>

    ) : null;



    return (
    <>
        <div className='ecg-tool-container'>
                <div className='ecg-tool-panel' style={{ 'width': w }}>
                {hrSection}
                {viewSection}
                {rhythmSection}
                {rrDiagramSection}
                {pwPlotSection}
                {hrvSection}
                {beatInfoSection}
            </div>
        </div>
        <style>{css}</style>
    </>
)
}

export default ToolPanel;