import React from 'react';

import { toTime, toProc } from './str-funcs';

const RSI_SINUS = 0x10;
const RSI_SA = 0x14;
const RSI_EX = 0x50;
const RSI_AF = 0x41;


export function toMS(tc, frequency) {
    return frequency > 0 ? Math.round(tc / frequency * 1000) : 0;
}

export function toNN(b, b0, na="N/A") {
    return b0 && b0.rr && b0.rr > 0 ? Math.round(100*b.rr / b0.rr) / 100 : na; 
}

export function toHR(tc, frequency, na="N/A") {
    return tc && tc > 0 ? Math.round(60 * frequency / tc) : na;
}

export function toST(b, na="N/A") {
    return b.a_amp && b.b_amp && b.q1_amp ? Math.round((Math.round(b.a_amp) + Math.round(b.b_amp)) / 2 - Math.round(b.q1_amp)) : na;
}

export function toPQ(b, frequency, na="N/A") {
    return b.p1r && b.q1r ? toMS(Math.round(b.p1r) - Math.round(b.q1r), frequency) : na;
}

export function toQT(b, frequency, na="N/A") {
    return b.q1r && b.rt2 ? toMS(Math.round(b.q1r) + Math.round(b.rt2), frequency) : na;
}

export function cutBeats(beats, offset, length) {
    return beats ? beats.filter(b => (b.r >= offset) && (b.r < offset + length)).map(b => {
        const bb = {...b}
        bb.o = offset;
        bb.r0 = bb.r - offset;
        // console.log(b.r0, offset, length);
        return bb;
    }) : null;
}

export function getRangeByBeatNo(beatNo, beats, frequency) {
   const b = beats.find((bb) => bb.i === beatNo);
   // console.log(b, beatNo, beats);
   if (b === undefined) {
      return {start:-1, finish:-1};
   }
   const s = Math.round(b.r) - Math.max(10, b.p1r ? Math.round(b.p1r) : 0);
   const f = Math.round(b.r) + Math.max(16, b.rt2 ? Math.round(b.rt2) : 0);
   return {start:s / frequency, finish:f / frequency};
}

export function findNearestBeat(tc, beats) {
    // console.log(tc);
    if (!beats) return null;

    let bNearest = beats[0];
    let dNearest =  Math.abs(tc - Math.round(bNearest.r));
    beats.forEach(b => {
        const d =  Math.abs(tc - Math.round(b.r));
        if (dNearest > d) {
            dNearest = d;
            bNearest = b;
        }
    })
    return bNearest;
}

export function beatAlign(tc, beats, offset=0) {
    if (!beats) {
        return tc;
    }
    const i = beats.findIndex(b => { return (parseInt(b.r) - parseInt(b.rr) / 2 <= tc) && (tc < (parseInt(b.r) + parseInt(b.rr) / 2 )) } );
    // console.log(b, tc);
    if (i < 0) return tc;

    const b = i + offset < beats.length ? beats[i + offset] : beats[i];
    return parseInt(b.r);
}

export function detectHR(beats, frequency) {
  let nn = 0;
  let sRR = 0;
  beats.slice(1, beats.length - 1).forEach(b => {
    if (b.rr > 0) {
      sRR += Math.round(b.rr);
      nn++;
    }
  });
  return Math.round(60 * frequency * nn / sRR );
}

export function detectHRV(beats, frequency) {
    if (beats === undefined || beats.length <= 1) {
        return {} 
    }
    const hDepth = 20;
    const rrHistMax = 2000;
    const hrHistMax = 200;
    let nn = 0;
    let sRR = 0;
    let rr_hist = new Array(hDepth).fill(0);
    let hr_hist = new Array(hDepth).fill(0);
    const b1 = beats.slice(1, beats.length - 1);
    b1.forEach(b => {
        if (b.rr > 0) {
            sRR += Math.round(b.rr);

            const hr = Math.round(60 * frequency / b.rr);
            const i_hr = Math.min(hDepth - 1, Math.round(hr / hrHistMax * hDepth));
            hr_hist[i_hr]++;

            const i_rr = Math.min(hDepth - 1, Math.round(1000 * b.rr / frequency / rrHistMax * hDepth));
            rr_hist[i_rr]++;

            nn++;
        }
    });
    const rrMean = Math.round(1000 * sRR / frequency / nn);
    let sDisp = 0; let sSD = 0; let rr0 = 0;
    let nn20 = 0; let nn50 = 0;
    b1.forEach(b => {
        const rr = Math.round(b.rr);
        if (rr > 0) {
            const d = rrMean - rr;
            sDisp += d * d;

            if (rr0 > 0) {
                const drr = Math.abs(1000 * (rr - rr0) / frequency);
                const dnn = Math.round(Math.abs(100 - 100 * rr / rr0));
                if (dnn <= 5) {
                    sSD += drr * drr;
                    if (drr >= 20) nn20++;
                    if (drr >= 50) nn50++;
                } else {
                    sRR -= rr;
                    nn--;
                }
            }

            rr0 = rr;
        }
    });


    const hr = nn >= 5 ? Math.round(60 * frequency * nn / sRR) : detectHR(beats, frequency);
    const sdnn = nn >= 5 ? Math.round(10 * Math.sqrt(sDisp) / (nn - 1)) / 10 : "N/A";
    const rmssd = nn >= 5 ? Math.round(10 * Math.sqrt(sSD / nn)) / 10 : "N/A";
    const pnn20 = nn > 0 ? Math.round(1000 * nn20 / nn) / 10 : "N/A";
    const pnn50 = nn > 0 ? Math.round(1000 * nn50 / nn) / 10 : "N/A";
    return { n: beats.length, nn: nn, hr: hr, rrMean: rrMean, sdnn: sdnn, rmssd: rmssd, nn20: nn20, pnn20: pnn20, nn50: nn50, pnn50: pnn50, 
        rr_hist: rr_hist, hr_hist: hr_hist };
}

export function detectRhythmStat(rhythm, frequency, totalLength) {
    const rsStat = {
        tcUndef: 0, tUndef: "N/A", pUndef: "", mUndef: 0,
        tcRS: 0, tRS: "N/A", pRS: "", mRS: 0,
        tcSA: 0, tSA: "N/A", pSA: "", mSA: 0,
        tcEx: 0, tEx: "N/A", pEx: "", mEx: 0,
        tcAF: 0, tAF: "N/A", pAF: "", mAF: 0,
        tcTotal: 0
    };
    if (rhythm) {
        rhythm.forEach(r => {

            let rrr = Math.round(r.r);
            const rf = Math.round(r.s) + rrr;
            if ( rf > totalLength) {
                rrr = Math.max(0, rrr - (rf - totalLength));
            }
            
            rsStat.tcTotal += rrr;
            switch (r.t) {
                case RSI_SINUS:
                    rsStat.tcRS += rrr; break;
                case RSI_SA:
                    rsStat.tcSA += rrr; break;
                case RSI_EX:
                    rsStat.tcEx += rrr; break;
                case RSI_AF:
                    rsStat.tcAF += rrr; break;
                default:
                    rsStat.tcUndef += rrr;
            }
            if (r.m > 0) {
                switch (r.t) {
                    case RSI_SINUS:
                        rsStat.mRS += 1; break;
                    case RSI_SA:
                        rsStat.mSA += 1; break;
                    case RSI_EX:
                        rsStat.mEx += 1; break;
                    case RSI_AF:
                        rsStat.mAF += 1; break;
                    default: 
                        rsStat.mUndef += 1;
                }

            }

        })
    }
    // console.log(rhythm);
    if (rsStat.tcTotal > 0) {
        rsStat.tRS = toTime(rsStat.tcRS, frequency); rsStat.pRS = toProc(rsStat.tcRS, rsStat.tcTotal);
        rsStat.tSA = toTime(rsStat.tcSA, frequency); rsStat.pSA = toProc(rsStat.tcSA, rsStat.tcTotal);
        rsStat.tEx = toTime(rsStat.tcEx, frequency); rsStat.pEx = toProc(rsStat.tcEx, rsStat.tcTotal);
        rsStat.tAF = toTime(rsStat.tcAF, frequency); rsStat.pAF = toProc(rsStat.tcAF, rsStat.tcTotal);
        rsStat.tUndef = toTime(rsStat.tcUndef, frequency); rsStat.pUndef = toProc(rsStat.tcUndef, rsStat.tcTotal);
    }
    return rsStat;
}


export function drawBarDiagram(data, xMax=2000, xStep=200, w=180, h=120, unit) {
    if (data === undefined) {
        return null;
    }
    // console.log(data);
    const ml = 10; const mb = 12; const mt=2;
    const items = [];
    const dataMax = Math.max(...data);
    const bw = (w - ml) / (1+data.length);
    let bx0 = ml;
    items.push((<line key={items.length} x1={ml} y1={h - mb + 2} x2={w} y2={h - mb + 2} style={{ stroke: "black", strokeWidth: "1px" }} />));
    let x = 0;
    const gTicks = [];
    while (x <=xMax) {
        const xx = Math.round(ml + (w - ml-1) * x / xMax);
        items.push((<line key={items.length} x1={xx} y1={0} x2={xx} y2={h - mb} style={{ stroke: "#888888", strokeWidth: "1px", strokeDasharray: 3 }} />));

        items.push((<line key={items.length} x1={xx} y1={h - mb+2} x2={xx} y2={h - mb + 5} style={{ stroke: "#808080", strokeWidth: "1px"}} />));
        const xx2 = Math.round(ml + (w - ml - 1) * (x + xStep / 2) / xMax);
        if (xx2 < w) {
            items.push((<line key={items.length} x1={xx2} y1={h - mb + 2} x2={xx2} y2={h - mb + 5} style={{ stroke: "#808080", strokeWidth: "1px" }} />));
        }
        if (x > 0 && x < xMax) {
            gTicks.push((<text key={gTicks.length} x={xx} y={h} >{x}</text>));
        }
        x += xStep;
    }
    items.push((<text key={items.length} x={w} y={h - 1} textAnchor="end" style={{ fontSize: "6" }}>{unit}</text>));
    items.push((<g key={items.length} textAnchor="middle" style={{ fontSize: "5" }}>{gTicks}</g>));

    const gBars = [];
    for (let i = 0; i < data.length; i++) {
        const bx = Math.round(ml + i * bw);
        const bwr = Math.round(bx - bx0 + 1);
        if (data[i] > 0) {
            const bh = Math.round((h - mb - mt) * data[i] / dataMax);
            gBars.push((<rect key={gBars.length} x={bx0+1} y={h - bh - mb + mt} width={bwr - 3} height={bh} />));
        }
        bx0 = bx;
    }
    items.push((<g key={items.length} fill="green"
        style={{ stroke: "black", strokeOpacity: 0.8, fillOpacity: 0.5 }}>{gBars}</g>));

    const css = `
svg {
    font: 10px Verdana, Helvetica, Arial, sans-serif;
}
`
    items.push((<style key={items.length}>{css}</style>));
    return (<svg xmlns="http://www.w3.org/2000/svg" width={w} height={h}> {items}</svg>);
}

export function detectBeatInfo(beats, frequency, beatNo) {
    if (beatNo >=0 ) {
        const b0 = beatNo > 0 ? beats[beatNo-1] : undefined;
        const b = beats[beatNo];
        console.log(b);
        return {n: beatNo+1, rr: toMS(b.rr, frequency), 
            nn: toNN(b, b0), nn1: b.nn ? b.nn / 100 : "-", nn2: b.nn_n ? b.nn_n / 100 : "-", 
            hr: toHR(b.rr, frequency), st: toST(b), pq: toPQ(b, frequency), qt: toQT(b, frequency), 
            cd: Math.round(b.cd), z: b.z ? Math.round(b.z) : "?", 
            snr: b.snr ? b.snr : "-", f_snr: b.f_snr ? `/ ${b.f_snr} ` : "",
            qi: b.qi? Math.round(b.qi) : "-", di: b.di ? Math.round(b.di) : "-", 
            w: Math.round(b.w), g: b.g === undefined ? "-" : Math.round(b.g), gc: b.gc ? Math.round(b.gc) : ""}
    }
    return null;
}